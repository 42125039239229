<template>
  <div class="block-space">
    <div class="container">
      <div class="row justify-content-center text-center mb-3">
        <div class="col-lg-8" v-if="title != '' && desc != ''">
          <h2 class="h3" v-if="title">{{ title }}</h2>
          <p class="lead text-muted" v-if="desc">{{ desc }}</p>
        </div>
      </div>
      <swiper
        class="swiper secretaries-swiper"
        :options="swiperOption"
        v-if="items && items.length > 0"
      >
        <swiper-slide v-for="member in items" :key="member.id">
          <img
            :src="member.image.route"
            class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
            style="width: 160px; height: 160px"
          />
          <h5 class="title text-center">
            <span class="d-block">{{ member.name + ' ' + member.lastname }}</span>
            <small class="h6 text-muted">{{ member.period }}</small>
          </h5>

          <v-row no-gutters>
            <img
              class="country__flag rounded-circle"
              v-if="member.countries.length > 0 && member.countries[0].flag != ''"
              :src="member.countries[0].flag"
              :alt="`País al que representa: ${member.countries[0].name}`"
            />
          </v-row>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      title: { type: String, required: false },
      desc: { type: String, required: false },
      items: { type: Array },
      loading: { type: Boolean, required: false },
    },

    data() {
      return {
        swiperOption: {
          speed: 300,
          centerInsufficientSlides: true,
          setWrapperSize: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1440: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1025: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },

            320: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          },
        },
      }
    },
  }
</script>
<style lang="scss" socped>
  .country__flag {
    margin: 0 auto;
    width: 35px;
    height: 35px;
  }
  .secretaries-swiper {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0px;
      right: auto;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0px;
      left: auto;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 1.5rem;
    }
  }
</style>
